<template lang="pug">
	#Premios
		ul
			li(v-for="item in items")
				.img
					img(:src="item.image") 
				.content
					p.text {{ item.text }}
					p.text {{ item.award }}

</template>

<script>
import { props } from '@/mixins/component'

export default {
	name: "component-premios-e-destaques",
	props,
	    data() {
        return {
            items: [],
        }
    },
    mounted() {
		this.items = this.contents.content.map(item => ({
			...item,
			image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`
		}))
	}
}
</script>

<style lang="stylus" scoped src="./PremiosEDestaques.styl"></style>
